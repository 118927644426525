.hero-about {
    height: 645px; 
    background: url(../images/bg_about.png) no-repeat top center; 
    background-size: cover;
    position: relative;
    .mdl-grid {
        padding-top: 14em;
    }
}

.hero-text {color: #fff; }
.section-pricing { 
    text-align: center;
    background: #E7E8EC;
    padding: 50px 0;
}
.pricing-table {
    margin-top: 50px;
    text-align: left;
    
}

/* Each pricing-item*/
.pricing-item {
  height: auto;
  -webkit-transition: all .2s;
  transition: all .2s;
    background: #fff;
    border-radius: 6px;
    overflow: hidden
}

.pricing-item:hover {
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px);
  }
.pricing-title {
    background: #f8f8f8;
    padding: 20px 40px;
    h5 {font-size: 18px; 
        display: inline-block;}
}
.pricing-item:hover .pricing-title {
    color: #fff;
    background: linear-gradient(90deg, #6CC0F7 0, #3A8CEB 0, #6CC0F7);
}
.mdl-list__item {
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
    color: #989898;
    padding: 30px 40px;
    font-family: Gotham;
    vertical-align: middle;
    
}
.mdl-list__item:last-child {border: none;}
.mdl-badge {float: right;}
.pricing-item:last-child .mdl-list__item{padding: 20px 40px;}
.visi-misi {margin-top: 100px;
    .mdl-card{ 
        width: 100%; 
        text-align: left; 
        margin: 10px; 
        border-radius: 6px; 
        overflow: hidden; 
        min-height: 160px;
        h3{
            margin-bottom: 10px;
            color: #262c64;
        }
    }
}
.mdl-card__supporting-text {border-left: 30px solid; border-image:linear-gradient(to bottom, #3A8CEB, #6CC0F7) 1 100%; color: #262c64; padding-left: 30px; margin-bottom: 0}
.section-team {
    background: url(../images/bg-team.png) no-repeat; background-size: cover; color: #fff;
    .slide-team{
        .desc{
            margin-top: 11em;
        }
    }
}
.social-media {
    margin-top: 2em;
    a {
        color: #fff; padding: 10px; margin-right: 10px;
        .fa{
            font-size: 22px;
        }
    }
}

/* section-gallery-team */
.section-gallery-team {text-align: center; background: url(../images/pattern-dot.png) repeat-x top center; padding: 50px 0;}
.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}
.team-slider{
    margin: 0 auto;
    margin-top: -11em;
    max-width: 1000px;
    .slick-list{
        overflow: visible;
    }
    .slick-slide{
        opacity: 1 !important;
    }
    .slick-dots{
        margin: -4em 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-end;
        z-index: 1;
        position: relative;
        width: 50%;
        padding-right: 3em;
        transform: translateY(-7em);
        li{
            button{
                width: 8px;
                height: 8px;
                background: #979797;
                border: 0;
                margin: 0 10px;
                color: transparent;
                padding: 0;
                border-radius: 50%;
            }
            &.slick-active{
                button{
                    background: $color-yellow;
                    transform: scale(1.3);
                }
            }
        }
    }
    .team-photo{
        transform: translate(-14em,0) scale(.8);
        transition: all 1s $easing-kdk;
    }
    .desc{
        opacity: 0;
        transition: all 1s $easing-kdk;
    }
    .slick-current{
        .team-photo{
            transform: translate(0,0) scale(1);
        }
        .desc{
            opacity: 1;
        }
    }
}
.teamgallery {
    max-width: 1140px;
    margin: 0 auto;
    
}
.teamgallery .mdl-cell{padding: 15px;}
/* section-team */
.section-team {margin-top: -10em; padding: 250px 0 100px; }
.section-team h4 {margin-bottom: 0; line-height: 34px;}
.section-team p.position{    font-weight: 500;
    font-size: 15px;}
.section-team h2{
    margin: 0;
}
/* section-contact */
input[type=text],
input[type=password], textarea {
    box-sizing : border-box;
    font-family: 'Avenir';
}
.gold {color: #edcd8d;    line-height: 58px;}
.section-contact {background: url(../images/bg-contactform.png); background-size: cover; color: #fff;padding-top: 50px; padding-bottom: 50px;}
.section-contact .wrap-center {max-width: 1140px;}
.section-contact h2, .section-contact h5,.section-contact h6 {color: #edcd8d; margin-bottom: 10px;max-width: 335px;}
.section-contact p {max-width: 335px;}
.form-contact .field-input{margin-bottom: 20px; padding: 0 1em;}