.is-light{
    .light-logo{
        display:none;
    }
    .dark-logo{
        display:block;
    }
    .section-hero{
        background: #cbdfe7;
    }
    header{ 
        ul li a{
            color: $color-blue;
        }
        .right-menu{ 
            .btn-phone{
                border-color: $color-blue;
                .fa{
                    color: $color-blue;
                }
                &:hover{
                    border-color:#ec1d24;
                    .fa{
                        color:#ec1d24;
                    }
                }
            }
            .btn-request{
                color: $color-white;
                &:hover{
                    color: $color-red;
                }
            }
        }
    }
    .hero-dark, 
    .tabs-dark{
        color:$color-blue;
    }
    .tabs ul li{
        .btn{
            color:$color-blue;
            border-color:$color-blue;
            &:hover{
                color:#fff;
                border-color:transparent;
            }
        }
        &.active{
            .btn{
                color:#fff;
                border-color:transparent;
            }
        }
    }
}

header.is-light{
    max-height: 80px;
    ul {
        li{
            a{
                color: $color-blue;
            }
            &.has-sub:before{
                background-image: url(../images/arrow-down-red.svg) !important;
            }
        }
        
    }
    .right-menu{ 
        .btn-phone{
            border-color: $color-blue;
            .fa{
                color: $color-blue;
            }
            &:hover{
                &:after{
                    background-color: $color-blue;
                }
                span, .fa{
                    color: $color-white;
                }
            }
        }
        .btn-request{
            color: #fff;
            &:hover{
                span{
                    color: $color-red;
                }
            }
        }
    }
}