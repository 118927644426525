.d-1{
    transition-delay: .2s !important;
}
.d-2{
    transition-delay: .4s !important;
}
.d-3{
    transition-delay: .6s !important;
}
.d-4{
    transition-delay: .8s !important;
}
.d-5{
    transition-delay: 1s !important;
}
.d-6{
    transition-delay: 1.2s !important;
}
.d-7{
    transition-delay: 1.4s !important;
}
.d-8{
    transition-delay: 1.6s !important;
}
.d-9{
    transition-delay: 1.8s !important;
}
.d-10{
    transition-delay: 2s !important;
}
.d-11{
    transition-delay: 2.2s !important;
}

.animated{
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: $easing-kdk;
    .layers{
        transition-property: all;
        transition-duration: .3s;
        transition-timing-function: $easing-mdl;
    }
}

.slideUp {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
}
.slideUp.inview {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.slideDown {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
}
.slideDown.inview {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.slideLeft {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
}
.slideLeft.inview {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.zoomIn{
    opacity: 0;
    transform: scale(.5);
}
.zoomIn.inview{
    opacity: 1;
    transform: scale(1);
}
.zoomOut{
    opacity: 0;
    transform: scale(1.5);
}
.zoomOut.inview{
    opacity: 1;
    transform: scale(1);
}

.slideRight{
    .layers{
        opacity: 0;
        transform: translateX(-50px);
    }
    &.run{
        .layers{
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.hero-slide{
    .slideRight{
        .layers{
            opacity: 0;
            transform: translateX(-50px);
            &.flip{
                transform: translateX(-50px) scaleX(-1);
            }
        }
    }
    &.slick-current{
        .slideRight{
            .layers{
                opacity: 1;
                transform: translateX(0);
                &.flip{
                    transform: translateX(0) scaleX(-1);
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(.8);
        opacity: 1;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

  @keyframes arrowOut{
    0%{opacity:0;-webkit-transform:translate(0%,-180%);transform:translate(0%,-180%)}
    50%,70%{-webkit-transform:translate(0%,-65%);transform:translate(0%,-65%);opacity:1}
    100%{-webkit-transform:translate(0%,25%);transform:translate(0%,25%);opacity:0}
}
@keyframes arrowRight{
    0%{opacity:0;-webkit-transform:translate(-180%, 0%);transform:translate(-180%, 0%)}
    50%,70%{-webkit-transform:translate(-65%, 0%);transform:translate(-65%, 0%);opacity:1}
    100%{-webkit-transform:translate(25%, 0);transform:translate(25%, 0);opacity:0}
}