.feature-page{
    .section-feature{
        min-height:750px;
        background: #E7E8EC url(../images/mac-pintro.png) no-repeat 45%;
        overflow: visible;
    }
    .hero-feature{
        .hero-title{
            z-index: 1;
            position: relative;
            h1{
                color:#262C64;
                opacity: 0;
                transform: translateY(50px);
                transition: 1s all $easing-kdk;
                &.inview{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            p{
                color:#323232;
                opacity: 0;
                transform: translateY(50px);
                transition: 1s all $easing-kdk;
                transition-delay:2s;
                @media screen and (max-width:768px){
                    margin:15px;
                }
                &.inview{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
        .hero-content{
            display:flex;
            flex-flow:row wrap;
            @media screen and (max-width:1200px){
                justify-content: center;
            }
            .hero-details{
                flex:1 0 25%;
                padding-top: 3em;
                z-index: 1;
                @media screen and (max-width:1200px){
                    flex:0 0 30%;
                    order: 1;
                    min-height: 400px;
                }
                @media screen and (max-width:992px){
                    flex:1 0 50%;
                }
                @media screen and (max-width:560px){
                    flex:1 0 100%;
                    min-height: 0;
                }
                .acc-list{
                    opacity: 0;
                    transition: 1s all $easing-kdk;
                    &.inview{
                        opacity:1;
                    }
                    .acc-title{
                        font-weight: $font-medium;
                    }
                }
                &:first-of-type{
                    padding-left: 5em;
                    .acc-list{
                        transform: translateX(-50px);
                        &.inview{
                            transform: translateX(0);
                        }
                        
                    }
                    @media screen and (max-width:1200px){
                        padding: 0 2em;
                    }
                }
                &:last-of-type{
                    padding-right: 5em;
                    .acc-list{
                        transform: translateX(50px);
                        &.inview{
                            transform: translateX(0);
                        }
                    }
                    @media screen and (max-width:1200px){
                        padding: 0 2em 2em;
                    }
                }
            }
            .hero-img{
                flex:1 0 50%;
                @media screen and (max-width:1200px){
                    flex:1 0 100%;
                    margin-bottom:2em;
                }
                img{
                    display:block;
                    height:auto;
                    margin:0 auto;
                    max-width:100%;
                    opacity: 0;
                    transform: translateY(50px);
                    transition: 1s all $easing-kdk;
                    transition-delay:2.5s;
                    position: absolute;
                    top: 4em;
                    left: 0;
                    right: 0;
                    border: 0;
                }
                &.inview{
                    img{
                        opacity: 1;
                        transform: translateY(0);
                        
                    }
                }
            }
        }
    }
    .section-work,
    .section-payment{
        padding:3em;
        background:#063170;
        z-index: 1;
        position: relative;
        @media screen and (max-width:768px){
            padding:3em 0 0;
        }
        .section-title{
            text-align:center;
            padding:0 5% 20px;
            color:#fff;
            .animated{
                opacity: 0;
                transform: translateY(50px);
                transition: 1s all $easing-kdk;
                &.inview{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
    .card{
        &.animated{
            opacity: 0;
            transform: translateY(50px);
            transition: 1s all $easing-kdk;
            &.inview{
                opacity: 1;
                transform: translateY(0);
            }
        }
        .content-img.animated{
            overflow: hidden;
            img{
                opacity: 0;
                transform: translateX(-100px);
                transition: 1s all $easing-kdk;
                transition-delay: 2s;
            }
            &.inview img{
                opacity: 1;
                transform: translateX(0);
            }
        }
        .content-details{
            .animated{
                opacity: 0;
                transform: translateY(1em);
                transition: 1s all $easing-kdk;
                &.inview{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
    .section-payment{
        .animated{
            opacity: 0;
            transform: translateY(50px);
            transition: 1s all $easing-kdk;
            @media screen and (max-width:768px){
                flex-flow: column wrap;
            }
            &.inview{
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}