.info-page{
    min-height: 700px;
    .info-title{
        background: $color-grey-border;
        padding: 10em 0 4em;
        >.wrap-center{
            padding: 0 16px;
        }
        .icon{
            width: 50px;
            height: 50px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            box-shadow: -3px 6px 10px 0px rgba(234,4,29,0.35);
            &.icon-privacy{
                background-image: url(../images/icon-privacy.svg);
            }
            &.icon-terms{
                background-image: url(../images/icon-terms.svg);
            }
        }
        h1{
            font-size: 52px;
            color: $color-blue;
            line-height: 1;
            margin: .75em 0 .1em;
        }
        p{
            color: $color-black-light;
            margin: 0;
        }
    }
    .info-content{
        padding: 5em 0;
        background: #F1F1F3;
        h5{
            line-height: 28px;
        }
        ol {
            counter-reset: section;
            list-style-type: none;
            padding: 0;
        }
        ol li{
            padding-left: 1.5em;
            position: relative;
        }
        ol li:before {
            counter-increment: section;
            content: counters(section, ".") "  ";
            position: absolute;
            left: 0;
            top: 0;
        }
        
        ol li li:before {
            counter-increment: section;
            content: counters(section, ".") "  ";
        }
        
        ol ol ol {
            counter-reset: list;
            margin: 0;
        }
        
        ol ol ol > li {
            list-style: none;
            position: relative;
        }
        
        ol ol ol > li:before {
            counter-increment: list;
            content: "(" counter(list, lower-alpha) ") ";
            position: absolute;
            left: -1.4em;
        }
        ul{
            padding: 0;
            list-style:none;
            li{
                position: relative;
                padding-left: 2em;
                &:before{
                    content:url(../images/icon-li.svg);
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    box-shadow: -3px 6px 10px 0px rgba(255,255,255,0.38);
                }
            }
        }
    }
}