.section-solutions{
    .animated{
        opacity:0;
        transform:translateY(50px);
        transition:all 1s $easing-kdk;
        &.inview{
            opacity:1;
            transform:translateY(0);
        }
    }
    .tabs ul > li{
        opacity:0;
        transform:translateY(50px);
        transition:all 1s $easing-kdk;
        font-weight: $font-medium;
        font-size: 15px;
        &.inview{
            opacity:1;
            transform:translateY(0);
        }
    }
    .hero-title{
        position:relative;
        z-index: 1;
    }
}
.section-customers .animated{
    opacity:0;
    transform:translateY(50px);
    transition:all 1s $easing-kdk;
    &.inview{
        opacity:1;
        transform:translateY(0);
    }
}