.accordion-wrap{
    padding:40px 0;
}
.accordion{
    margin-bottom:10px;
    .accordion-toggle{
        display: flex;
        align-items: center;
        background: rgba(93,153,240,0.1);
        padding:10px 15px;
        border-radius: 35px;
        margin: 0 40px;
        @media screen and (max-width:768px){
            margin: 0 20px;
        }
        label{
            font-weight: $font-medium;
            font-size: 22px;
            margin-bottom: -14px;
            height: 20px;
            line-height: 22px;
        }
    }
    .accordion-number{
        height: 45px;
        width: 45px;
        border-radius: 100%;
        background: linear-gradient(236.68deg, #6CC0F7 -2.14%, #3A8CEB 62.2%);
        color: #fff;
        line-height: 58px;
        margin-right: 15px;
        font-size: 22px;
    }
    .accordion-indicator{
        height: 45px;
        width: 45px;
        border-radius: 100%;
        background: #ec1d24;
        line-height: 58px;
        margin-left: auto;
        .caret{
            background: url(../images/arrow-down.svg) no-repeat 50%;
            background-size: 100%;
            height: 15px;
            width: 15px;
            display: inline-block;
            transition: all 0.2s ease-in-out;
        }
    }
    .accordion-content{
        display: flex;
        text-align: left;
        flex-flow:row wrap;
        @media screen and (max-width:768px){
            flex-flow:column wrap; 
        }
        ul{
            display:none;
            flex:1 0 50%;
            padding-left:0;
            margin-bottom:25px;
            li{
                padding:15px 55px 5px;
                list-style:none;
                display:flex;
                align-items: center;
                @media screen and (max-width:768px){
                    padding:10px 35px;
                }
                &.title{
                    font-weight: $font-medium;
                    font-size: 18px;
                    border-radius: 30px;
                    padding: 22px 10px 10px 75px;
                    margin-left: 40px;
                    width: calc(100% - 40px);
                    height: 60px;
                    display: block;
                    position: relative;
                    i{
                        display: block;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                    }
                }
                i{
                    background-color: $color-blue;
                    background-repeat: no-repeat;
                    background-position:center;
                    background-size: 100%;
                    height: 40px;
                    width: 40px;
                    margin-right:10px;
                    border-radius:100%;
                }
                &:not(:first-child):before{
                    content:"";
                    background:url('../images/icon-check-bolder.svg') no-repeat center;
                    height:13px;
                    width:40px;
                    background-size: 13px;
                    margin-right: 10px;
                    flex: 0 0 40px;
                    margin-top: -10px;
                }
                &:nth-child(odd){
                    background:rgba(239,239,239,0.6);
                }
                &.filler:before{
                    display: none;
                }
            }
            &.right-side{
                li{
                    &.title{
                        margin: 0;
                        float: right;
                        margin-right: 40px;
                        width: calc(100% - 80px);
                    }
                }
            }
        }
    }
    &.active{
        .accordion-indicator .caret{
            transform: rotate(180deg);
        }
    }
}
.acc-list{
    margin-bottom:15px;
    ul{
        display: none;
        list-style:none;
        margin:0;
        padding-left: 70px;
        li{
            font-size:13px;
            color:#000;
            position: relative;
            display:flex;
            align-items:center;
            &:before{
                content: "";
                width: 5px;
                height: 5px;
                border: thin solid #262c64;
                border-radius: 50%;
                display: block;
                position: absolute;
                left: -15px;
                top: 6px;
                margin-right:10px;
            }
        }
    }
    label{
        color:#000;
    }
    a{
        padding-left: 53px;
        font-size:13px;
        margin-top: 5px;
        font-weight: $font-medium;
        &:before{
            content:"";
            background: url(../images/arrow-down-red.svg) no-repeat 50%;
            background-size: 100%;
            height: 10px;
            width: 10px;
            margin-right: 13px;
            display: inline-block;
            vertical-align: middle;
            transition: all 0.2s ease-in-out;
        }
    }
    img{
        margin-right: 10px;
    }
    &.active{
        label{
            color:#262c64;
        }
        a:before{
            transform: rotate(180deg);
        }
    }
    
    
}
.icon-portal{
    background-image: url(../images/f-portal.svg);
}
.icon-enrollment{
    background-image: url(../images/f-enrollment.svg);
}
.icon-payment{
    background-image: url(../images/f-payment.svg);
}
.icon-raport{
    background-image: url(../images/f-raport.svg);
}
.icon-classroom{
    background-image: url(../images/f-classroom.svg);
}
.icon-library{
    background-image: url(../images/f-library.svg);
}
.icon-finance{
    background-image: url(../images/f-finance.svg);
}
.icon-hrd{
    background-image: url(../images/f-hrd.svg);
}