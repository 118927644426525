.hero-title{
    padding-top:5em;
    text-align: center;
    h1{
        font-size: 42px;
        margin-bottom:10px;
    }
    p{
        font-size: 13px;
        width:50%;
        margin:0 auto;
        @media screen and (max-width:768px){
            width:90%;
        }
    }
}
// .feature-page{
//     padding-top: 5em;
//     .hero-title{
//         padding-top: 0;
//     }
// }
