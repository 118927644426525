
.section-hero{
    position: relative;
    overflow: hidden;
    background: $color-blue;
    .hero-home{
        .slick-track{
            height: 100vh;
            transform: none !important;
        }
    }
    .hero-slide{
        height: 100vh;
        min-height: 600px;
        position: relative;
        overflow: hidden;
        background-position: 30% top;
        background-size: auto 120%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scale(1);
        transition: 3s all $easing-kdk;
        z-index: 0;
        opacity: 0;
        
        .hero-bg{
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            .pieces{
                position: absolute;
                left: 0;
                bottom: 0;
                transition-property: all;
                transition-duration: 1s;
                transition-timing-function: $easing-kdk;
                transition-delay: .5s;
                opacity: 0;
            }
        }
        .hero-caption{
            position: absolute;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            >.wrap-center{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
            .hero-featured{
                width: 50%;
                height: 100%;
                position: relative;
                opacity: 0;
                .layers{
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition-property: all;
                    transition-duration: .5s;
                    transition-timing-function: $easing-mdl;
                }
            }
            .hero-desc{
                width: 50%;
                padding-top: 8em;
                h2{
                    font-size: 45px;
                    font-weight: $font-bold;
                    color: $color-white;
                    margin: 0;
                    line-height: 58px;
                    margin-bottom: 10px;
                    transform: translateY(50px);
                    opacity: 0;
                    span{
                        display: block;
                        color: $color-yellow;
                    }
                }
                p{
                    color: $color-white;
                    font-size: 16px;
                    max-width: 450px;
                    transform: translateY(50px);
                    opacity: 0;
                }
                .hero-links{
                    margin-top: 5em;
                    a{
                        color: $color-white;
                        padding: 1em 1em 1em 2em;
                        margin-right: 1em;
                        font-size: 18px;
                        position: relative;
                        display: inline-block;
                        line-height: 30px;
                        .icon{
                            position: absolute;
                            left: 0;
                            top: 10px;
                            &:before{
                                content:'';
                                display: block;
                                width: 25px;
                                height: 30px;
                                background-size: 100%;
                                background-repeat: no-repeat;
                            }
                            &.icon-apple:before{
                                background-image: url(../images/icon-apple.svg);
                            }
                            &.icon-playstore{
                                top:13px;
                                &:before{
                                    background-image: url(../images/icon-playstore.svg);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.slide0{
            background-image: url(../images/bg-slide1.jpg);
            .hero-bg{
                .piece1{
                    left: -17em;
                    bottom: auto;
                    top: 5em;
                    transform: translate(-30em,-10em) rotate(-45deg);
                }
                .piece2{
                    left: 6em;
                    bottom: -25em;
                    top: auto;
                    transform: translate(0) rotate(-50deg);
                }
                .piece3{
                    left: auto;
                    right: -20em;
                    bottom: auto;
                    top: 0;
                    transform: translate(10em) rotate(10deg);
                }
            }
            .hero-featured{
                .layer1{
                    right: 7.5em;
                    top: 12.7em;
                    transform: translate(-10em,4em);
                    width: 220px;
                }
                .layer2{
                    right: 9.5em;
                    top: 15em;
                    transform: translate(-5em,2em);
                    width: 280px;
                }
                .layer3{
                    right: 17em;
                    top: 15em;
                }
                .layer4{
                    top: 5em;
                    right: 0em;
                    width: 100px;
                    height: 120px;
                    transform: scale(.8);
                    opacity: 0;
                }
            }
        }
        &.slide1{
            background-image: url(../images/bg-slide2.jpg);
            .hero-bg{
                .piece1{
                    left: -13em;
                    bottom: -13em;
                    transform: translate(-5em, 5em) rotate(-15deg);
                }
                .piece2{
                    right: 5em;
                    left: auto;;
                    bottom: -5em;
                    transform: translate(5em, 5em) rotate(20deg);
                }
                .piece3{
                    left: auto;
                    right: -6em;
                    bottom: auto;
                    top: 0;
                    transform: translate(5em, 0) rotate(20deg);
                }
            }
            .hero-featured{
                opacity: 0;
                transition: all .3s $easing-kdk;
                .layer1{
                    right: 2em;
                    top: 15em;
                    transform: translate(-5.5em,3.5em);
                }
                .layer2{
                    right: 2em;
                    top: 18em;
                }
                .layer3{
                    right: 34em;
                    top: 12em;
                    width: 84px;
                    height: 100px;
                    transform: translate(1em, 1em) scaleX(-1);
                    opacity: 0;
                }
            }
        }
        &.slide2{
            background-image: url(../images/bg-slide3.jpg);
            .hero-bg{
                .piece1{
                    left: -20em;
                    bottom: -8em;
                    transform: translate(-5em, 5em) rotate(-15deg);
                }
                .piece2{
                    right: 5em;
                    bottom: -10em;
                    left: auto;
                    top: auto;
                    width: 350px;
                    transform: translate(5em, 5em) rotate(20deg);
                }
                .piece3{
                    right: -10em;
                    bottom: auto;
                    left: auto;
                    top: 1em;
                    transform: translate(5em, 0) rotate(20deg);
                }
            }
            .hero-featured{
                .layer1{
                    right: 3em;
                    top: 15em;
                    transform: translate(-3em,3.5em);
                    
                }
                .layer2{
                    right: 5em;
                    top: 18em;
                }
                .layer3{
                    right: 33em;
                    top: 8em;
                    width: 84px;
                    height: 100px;
                    transform: translate(1em, 1em) scaleX(-1);
                    opacity: 0;
                }
            }
        }

        &.inview{
            z-index: 1;
            transform: scale(1);
            opacity: 1;
            .hero-bg{
                .pieces{
                    transform: translate(0,0) rotate(0);
                    opacity: 1;
                    
                }
            }
            .hero-featured{
                opacity: 1;
                .layers{
                    transform: translate(0,0);
                    opacity: 1;
                }
            }
            &.slide0{
                .layer4{
                    transform: scale(1);
                    opacity: 1; 
                }
                .piece2{
                    transform: translate(0,0) rotate(-30deg);
                }
            }
            &.slide1{
                .layer3{
                    transform: translate(0) scaleX(-1);
                    opacity: 1;
                }
            }
            &.slide2{
                .layer3{
                    transform: translate(0) scaleX(-1);
                    opacity: 1;
                }
            }
            .hero-caption{
                .hero-desc{
                    h2,p{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
        // &.slick-current{
        //     .hero-caption{
        //         .hero-desc{
        //             h2,p{
        //                 opacity: 1;
        //                 transform: translateY(0);
        //             }
        //         }
        //     }
        // }
    }
    .slick-dots{
        margin: 2em 0;
        list-style: none;
        display: flex;
        justify-content: left;
        width: 50%;
        padding-left: calc(50% - 8px);
        position: absolute;
        bottom: 13.5em;
        opacity: 0;
        transition-delay: 1s !important;
        &.inview{
            opacity: 1;
        }
        li{
            button{
                width: 8px;
                height: 8px;
                background: rgba(255,255,255,.3);
                border: 0;
                margin: 0 10px;
                color: transparent;
                padding: 0;
                border-radius: 50%;
            }
            &.slick-active{
                button{
                    background: $color-yellow;
                    transform: scale(1.3);
                }
            }
        }
    }
    .section-next{
        position: absolute;
        bottom: 3em;
        left: 0;
        right: 0;
        text-align: center;
        .btn-scroll{
            color: $color-white;
            font-size: 14px;
            width: 100px;
            display: block;
            margin: auto;
            text-align: center;
            position: relative;
            .icon-scrolldown{
                animation: arrowOut 2.4s infinite;
                position: absolute;
                top: -15px;
                right: 0;
                left: 0;
                margin: auto;
                transform: translate(0%,-65%);
                &:before{
                    content: '';
                    width: 15px;
                    height: 15px;
                    display: block;
                    background: url(../images/icon-scrolldown.svg) no-repeat;
                    margin: .5em auto;
                }
            }
        }
    }
}
.section-merchant{
    position: relative;
    overflow: hidden;
    &:before{
        content:'';
        display: block;
        width: 100%;
        height: 5em;
        background: url(../images/merchant-shadow.png) no-repeat bottom;
        background-size: 100% auto;
        position: absolute;
        top: 0;
    }
    .merchant-logos{
        filter: grayscale(100%);
        padding-bottom: 10em;
        background: url(../images/bg-merchant.png) no-repeat bottom;
        background-size: cover;
        ul{
            list-style: none;
            padding: 1em 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            li{
                flex-grow: 1;
                img{
                    height: 60px;
                    opacity: .7;
                }
                &:nth-child(1) img{
                    height: 50px;
                }
                &:nth-child(2) img{
                    height: 55px;
                }
                &:nth-child(3) img{
                    // height: inherit;
                }
                &:nth-child(4) img{
                    height: 50px;
                }
                &:nth-child(5) img{
                    height: 40px;
                }
                &:nth-child(6) img{
                    height: 50px;
                }
                &:nth-child(7) img{
                    height: 50px;
                }
                &:nth-child(8) img{
                    height: 53px;
                }
                &:nth-child(9) img{
                    height: 53px;
                }
                &:nth-child(10) img{
                    height: 40px;
                }
            }
        }
    }
    .pintro-info{
        background: $color-white;
        .pintro-video{
            margin-top: -9em;
            text-align: center;
            position: relative;
            .btn-play{
                position: absolute;
                border: 0;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                width: 100%;
                max-width: 580px;
                margin: 0 auto;
                background: transparent url(../images/icon-play.svg)no-repeat;
                background-position: center;
                background-size: initial;
                &:hover{
                    transform: scale(1.1);
                }
            }
            img{
                max-width: 580px;
                cursor: pointer;
            }
            .pintro-video-wrapper{
                width: 580px; height: 350px;
                margin: auto;
            }
        }
        .pintro-counts{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            max-width: 750px;
            margin: 1em auto;
            .pintro-count-item{
                flex-grow: 1;
                display: flex;
                padding: 0 0 0 1em;
                >div{
                    vertical-align: top;
                    &:first-child{
                        font-size: 50px;
                        line-height: 40px;
                        padding-right: 15px;
                        font-family: $gotham;
                        font-weight: $font-medium;
                        line-height: 60px;
                    }
                    &:last-child{
                        font-size: 18px;
                        max-width: 100px;
                        font-family: $gotham;
                        font-weight: $font-regular;
                    }
                }
            }
        }
        .pintro-services{
            text-align: center;
            >p{
                max-width: 600px;
                margin: 1em auto 3em;
                font-weight: $font-medium;
                font-size: 18px;
            }
            ul{
                list-style: none;
                padding: 0;
                display: flex;
                margin: 2em 0 3em;
                li{
                    flex-grow: 1;
                    position: relative;
                    .icon-wrap{
                        height: 50px;
                        margin-bottom: 1em;
                        img{
                            max-height: 50px;
                        }
                    }
                    p{
                        font-weight: $font-medium;
                    }
                    &:after{
                        content:'';
                        display: block;
                        width: 50px;
                        height: 1px;
                        background-color: $color-grey;
                        position: absolute;
                        right: -2em;
                        top: 2em;
                    }
                    &:last-child:after{
                        display: none;
                    }
                    &.d-9{
                        img{
                            min-height: 50px;
                        }
                    }
                }
            }
        }
    }
}
.pintro-popup{
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: table;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,1);
    transition: all .5s $easing-mdl;
    opacity:0;
    visibility: hidden;
    &.open{
        opacity: 1;
        visibility: visible;
    }
    .video-wrapper{
        max-width: 700px;
        margin: 0 auto;
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        text-align: center;
        .btn-close{
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #eee url(../images/icon-close.svg) no-repeat center;
            position: absolute;
            background-size: 10px 10px;
            margin: auto;
            right: 0;
            left: 0;
            top: -50px;
            border: 0;
            &:before{
                content: '';
                display: block;
                position: absolute;
                border: thin solid #eee;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 50%;
                transform: scale(1.3);
            }
        }
        .video-box{
            max-width: 800px;
            margin: auto;
            padding: 1em;
            background: $color-white;
            border-radius: 15px;
            position: relative;
        }
    }
}
.section-environment{
    padding: 3em 0;
    text-align: center;
    background: url(../images/bg-environment.png) no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    h2{
        color: $color-yellow;
        font-family: $gotham;
        font-weight: $font-medium;
        font-size: 42px;
        margin-bottom: 10px;
    }
    p{
        color: $color-white;
    }
    img{
        max-width: 100%;
        margin: 3em 0;
    }
    svg{
        margin: 3em 0;
    }
    .platform-wrapper{
        position: relative;
        width: 1250px;
        @media(max-width: 1370px){
            transform: scale(.95);
        }
        .platform-pops{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .pop-group{
            position: absolute;
            .pop-btn{
                width: 25px;
                height: 25px;
                display: block;
                background: $color-red url(../images/icon-plus.svg) no-repeat center 80%;
                border-radius: 50%;
                background-position: center;
                background-size: 60%;
                position: relative;
                &:before{
                    content: '';
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    border: thin solid $color-red;
                    transition: none;
                    animation: pulse 2s ease-out infinite;
                    display: block;
                    position: absolute;
                    left: -6px;
                    top: -6px;
                }
                &:after{
                    content: '';
                    border-radius: 50%;
                    width: 45px;
                    height: 45px;
                    border: 4px solid rgba(239, 59, 60, .3);
                    transition: none;
                    animation: pulse 2s ease-out infinite;
                    display: block;
                    position: absolute;
                    left: -14px;
                    top: -14px;
                }
            }
            .pop-box{
                opacity: 0;
                visibility: hidden;
                transition: all .3s $easing-mdl;
                position: relative;
                padding: 2em;
                text-align: left;
                max-width: 300px;
                background: rgba(255,255,255,.85);
                border-radius: 10px;
                transform: translate(-2em, -60%);
                h5{
                    margin: 0 0 10px;
                    font-size: 18px;
                    color: $color-blue;
                }
                p{
                    margin: 0;
                    font-size: 13px;
                    color: $color-black-light;
                    line-height: 22px;
                }
                &:after{
                    content:'';
                    width: 0;
                    height: 0;
                    display: block;
                    border-style: solid;
                    border-width: 10px 0 10px 20px;
                    border-color: transparent transparent transparent rgba(255,255,255,.85);
                    position: absolute;
                }
                &.right{
                    margin-left: 4em;
                    &:after{
                        left: -20px;
                        top: 45%;
                        transform: rotate(180deg);
                    }
                }
            }
            &.open{
                .pop-box{
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, -60%);
                    z-index: 1;
                }
                .pop-btn{
                    transform: rotate(45deg);
                    background-color: $color-white;
                    background-image: url(../images/icon-plus-red.svg);
                }
            }
            &#pop1{
                bottom: 45px;
                left: 400px;
                z-index: 1;
            }
            &#pop2{
                bottom: 270px;
                left: 330px;
                height: 100px;
                &.open{
                    z-index: 2;
                }
            }
            &#pop3{
                bottom: 210px;
                left: 415px;
                z-index: 1;
            }
            &#pop4{
                left: 380px;
                top: 190px;            
            }
            &#pop5{
                left: 450px;
                top: 120px;
            }
            &#pop6{
                right: 255px;
                top: 160px;
            }
            &#pop7{
                right: 242px;
                top: 105px;
            }
            &#pop8{
                right: 192px;
                top: 220px;
            }
            &#pop9{
                right: -3px;
                top: 150px;
            }
            &#pop10{
                bottom: 20px;
                left: 465px;
                z-index: 1;
            }
            &#pop11{
                right: -94px;
                bottom: 215px;
            }
        }
    }
    // .btn-pop{
    //     cursor: pointer;
    //     position: relative;
    //     .pulse1{
    //         transform: translate(435px, 204px);
    //         animation: pulse 2s infinite;
    //     }
    //     .pulse2{
    //         transform: translate(425px,199px);
    //         animation: pulse 2s infinite;
    //     }
    //     &:hover{
    //         path{
    //             &:nth-child(1){
    //                 fill: $color-white;
    //             }
    //             &:nth-child(2){
    //                 fill: $color-red;
    //             }
    //         }
    //         +.pop-box{
    //             opacity: 1;
    //             visibility: visible;
    //         }
    //     }
    // }
}
.section-customers{
    padding: 5em 0 0;
    text-align: center;
    background: $color-white url(../images/bg-map.png) no-repeat center 4em;
    background-size: auto auto;
    transition-duration: 3s !important;
    &.customers--dark{
        background: $color-blue url(../images/bg-map-dark.png) no-repeat center 4em;
        h2{
            color: $color-white;
        }
        .form-customer .field-submit{
            border: 0;
            color: #fff;
            &:hover{
                color: $color-red;
            }
        }
        .review-customer{
            &::before {
                content: url(../images/icon-quote-gold.svg);
            }
            .customer-slider {
                .customer-testi,
                .customer-position{
                    color:#fff;
                }
                .customer-name{
                    color:$color-yellow
                }
            }
                
        }
    }
    h2{
        color: $color-blue;
        font-family: $gotham;
        font-weight: $font-medium;
        font-size: 42px;
        margin-bottom: 10px;
    }
    .map-customer{
        p{
            color: $color-black-light;
        }
    }
    .form-customer{
        max-width: 650px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        .field-submit{
            margin-top: 1em;
        }
        .nice-select{
            color: $color-black-light;
            font-size: 15px;
            line-height: 56px;
        }
    }
    .review-customer{
        padding: 3em;
        &:before{
            content:url(../images/icon-quote.svg);
            margin: 0 auto .8em;
            display: block;
        }
        .customer-slider{
            .customer-testi{
                font-size: 18px;
                margin-bottom: 1em;
            }
            .customer-photo{
                width: 58px;
                height: 58px;
                overflow: hidden;
                margin: auto;
                border-radius: 50%;
                border: thin solid $color-grey;
                img{
                    width: 100%;
                }
            }
            .customer-name{
                color: $color-red;
                font-size: 18px;
                margin: .8em 0;
            }
            .customer-position{
                font-size: 15px;
            }
            .slick-dots{
                margin: 2em 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;
                padding-left:0;
                li{
                    button{
                        width: 8px;
                        height: 8px;
                        background: #979797;
                        border: 0;
                        margin: 0 10px;
                        color: transparent;
                        padding: 0;
                        border-radius: 50%;
                    }
                    &.slick-active{
                        button{
                            background: $color-yellow;
                            transform: scale(1.3);
                        }
                    }
                }
            }
        }
    }
}
.section-appdemo{
    background: url(../images/bg-app.png)no-repeat;
    background-size: cover;
    padding: 5em 0 3em;
    position: relative;
    overflow: hidden;
    h2{
        color: $color-yellow;
        font-family: $gotham;
        font-weight: $font-medium;
        font-size: 42px;
        margin-bottom: 10px;
    }
    p{
        color: $color-white;
    }
    img{
        margin-bottom: -5em;
        max-width: 400px;
    }
    .right-side{
        padding: 5em 0;
    }
    .download-links{
        display: flex;
        margin-top: 2em;
        .btn{
            margin-right: 1em;
            line-height: 60px !important;
        }
    }
}