@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
      url('../fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Light.woff2') format('woff2'),
      url('../fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
      url('../fonts/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.woff2') format('woff2'),
      url('../fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
      url('../fonts/Gotham-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Black.woff2') format('woff2'),
      url('../fonts/Gotham-Black.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$gotham: 'Gotham', sans-serif;
$avenir: 'Avenir Next', sans-serif;
$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bolder: 600;
$font-bold: 700;

html, body {
    font-family: $gotham;
    font-weight: $font-regular;
    margin: 0;
    padding: 0;
    font-size: 16px;
}
h1, h2, h3, h4, h5, h6{
    font-family: $gotham;
    font-weight: $font-medium;
    letter-spacing: 0;
}
p{
    font-family: $gotham;
    font-size: 16px;
    font-weight: $font-regular;
    line-height: 28px;
    margin-top: 1px;
    &.limit{
        overflow: hidden;
        position: relative;
        &:after{
            content: '...';
            display: block;
            padding: 2px 10px;
            background: #fff;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    b, strong{
      font-weight: $font-medium;
    }
}
ul li, ol li{
    font-family: $gotham;
    font-size: 16px;
    font-weight: $font-regular;
    line-height: 28px;
}
button,input[type="submit"],a{
    font-family: $gotham;
    font-weight: $font-regular;
}
