.tabs{
    transition:all 1s ease-out;
    >.wrap-center{
        max-width: 100%;
        padding: 0 1.5em;
    }
    .btn{
        padding: 0 2em;
    }
    .tabs-bg {
        background-size: cover;
        background-position: bottom;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        display: none;
        @media screen and (max-width:1200px){
            display: none !important;
        }
    }
    ul{
        list-style: none;
        display:flex;
        align-items: center;
        justify-content: center;
        padding-left:0;
        position:relative;
        z-index: 2;
        flex-wrap:wrap;
        // @media screen and (max-width:768px){
        //     flex-wrap:wrap;
        // }
        li {
            flex-grow: 0;
            flex-basis: 0;
            padding:0 10px;
            margin-bottom: 20px;
            @media screen and (max-width:768px){
                flex: 0 0 50%;
            }
            @media screen and (max-width:400px){
                flex: 0 0 100%;
            }
            .btn{
                border:thin solid hsla(0,0%,100%,.3);
                display:block;
                text-align:center;
                line-height:65px;
                &:after{
                    z-index:0;
                    background:linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
                }
                &:hover,&.active{
                    border-color:transparent;
                    color:#fff;
                    
                }
            }
            &.active{
                .btn{
                    border-color:transparent;
                    color:#fff;
                    &:after{
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}
.tabs-dark,
.hero-dark{
    color:#fff;
}
.tabs-dark{
    h3{
        color:#edcd8d;
    }
}
.tabs-content{
    & > div:not(.tabs-active){
        display:none;
    }
}
.tabs-details{
    display:flex;
    justify-content: flex-end;
    position:relative;
    z-index: 1;
    .img-details{
        width: 50%;
        img{
            max-width: 100%;
            display: none;
            &.active{
                display: block;
            }
        }
    }
    .tabs-info{
        width:50%;
        @media screen and (max-width:1200px){
            width:100%;
            padding:20px 50px;
        }
        h3{
            font-size:30px;
            margin-bottom:10px;
        }
        p{
            font-size:13px;
        }
    }
}
#payment{
    .tabs-details{
        .img-details{
            img{
                max-width: none;
                width: 200%;
                margin-left: -50%;
                margin-top: -5em;
            }
        }
    }
}
#classroom{
    .tabs-details{
        .img-details{
            img{
                max-width: 110%;
                margin-left: -8em;
                margin-top: -5em;
            }
        }
    }
}
#finance{
    .tabs-details{
        .img-details{
            img{
                max-width: 130%;
                margin-left: -10em;
                margin-top: 0;
            }
        }
    }
}
#library{
    .tabs-details{
        .img-details{
            img{
                max-width: 145%;
                margin-left: -8em;
                margin-top: -5em;
            }
        }
    }
}

#accounting{
    .tabs-details{
        position: relative;
        padding-bottom: 8em;
        .wrap-center{
            width: 100%;
        }
        .tabs-info{
            padding-left: 3em;
            padding-right: 5em;
            float: right;
        }
        .img-details{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}
#hrd{
    .tabs-details{
        position: relative;
        padding-bottom: 8em;
        .wrap-center{
            width: 100%;
        }
        .tabs-info{
            padding-left: 3em;
            padding-right: 5em;
        }
        .img-details{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}
.tabs-left .tabs-details{
    justify-content: flex-start
}
.content-list{
    ul{
        flex-wrap:wrap;
        align-items: flex-start;
        justify-content:flex-start;
        li{ 
            flex:1 0 100%;
            display:flex;
            align-items: center;
            padding:0;
            margin:20px 0;
            .number{
                flex:0 0 45px;
                width:45px;
                height:45px;
                line-height: 52px;
                // box-shadow: 0 0 0px 10px rgba(255,255,255,.2);
                margin-right:2em;
                text-align:center;
                font-size: 11px;
                color: #262c64;
                background: #fff;
                border-radius:100%;
                position: relative;
                &:before{
                    content:'';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: 50%;
                    box-shadow: 0 0 0px 10px rgba(255,255,255,.2);
                }
            }
            &:hover, &.active{
                cursor: pointer;
                .number{
                    color: $color-red;
                    &:before{
                        animation: pulse 2s ease-out infinite;
                    }
                }
            }
        }
    }
    &.list-row{
        @media screen and (max-width:768px){
            ul{
                flex-direction: column;
            }
        }
        li{
            flex:0 0 30%;
            padding-right:20px;
            align-items: flex-start;
            flex-direction: column;
            @media screen and (max-width:768px){
                flex-direction: row;
                align-items: center;
            }
            .number{
                margin-right: 2em;
                margin-bottom: 2em;
                @media screen and (max-width:768px){
                    margin-bottom: 0;
                    margin-right:2em;
                }
            }
        }
    }
}
.card-tabs{
    ul{
        display: flex;
        list-style: none;
        margin: 0;
        padding:0;
        @media screen and (max-width:768px){
            flex-flow:column wrap;
        }
        li{
            flex:1 0 0;
            a{
                display: block;
                background:#fff;
                text-align:center;
                padding:20px 0;
            }
            &.active{
                a{
                    background:linear-gradient(199.22deg, #6CC0F7 -2.14%, #3A8CEB 62.2%);
                    color:#fff;
                }
            }
        }
    }
}
.card-tabs-content{
    background:#F2F0F0;
    .tabs-contents{
        display:none;
        &.tabs-active{
            display: block;
            .content-wrapper{
                .content-img{
                    img{
                        transform:scale(0.7);
                    }
                }
            }
        }
    }
    .delay1{
        transition-delay: 0.1s !important;
        img{
            transition-delay: 0.5s !important;
            transform: scale(1);
        }
        p{
            transition-delay: 0.3s !important;
        }
    }
    .content-wrapper{
        display:flex;
        padding:40px 20px;
        @media screen and (max-width:768px){
            flex-flow:column wrap;
        }
        > div{
            flex:1 0 50%;
        }
        .content-img{
            img{
                display:block;
                height:auto;
                max-height: 400px;
                max-width: 100%;
                margin:0 auto;
            }
        }
        h5{
            color:$color-blue;
        }
    }
    .img-circle{
        display:inline-block;
        padding:15px;
        border-radius:100%;
        background: linear-gradient(235.68deg, #6CC0F7 -2.14%, #3A8CEB 62.2%);
    }
    .content-details{
        padding:20px 0;
    }
}