/// @type List

/// @type Length

/// @type Color
$color-white:#fff;
$color-black: #000;
$color-black-light: #4a4a4a;
$color-grey: rgba(216, 216, 216, .2);
$color-grey-light: rgba(216, 216, 216, .2);
$color-grey-border: #e7e7e7;
$color-grey-text: #999;
$color-blue: #262C64;
$color-blue-bg: #1A2E64;
$color-yellow: #EDCD8D;
$color-red: #EC1D24;
$easing-mdl: cubic-bezier(0.4, 0.0, 0.2, 1);
//$easing-medium: cubic-bezier(.25,.46,.45,.94);
$easing-kdk: cubic-bezier(.22,.61,.36,1);

*{
    box-sizing: border-box;    
}
html, body{
    color: $color-blue;
    position: relative;
}
a{
    color: $color-blue;
    font-weight: $font-regular;
    &:hover{
        color: $color-yellow;
    }
}
hr{
    border-color:#9B9B9B;
}
a, button, input{
    transition: all .3s $easing-mdl;
}
a, button, input[type="submit"]{
    cursor: pointer;
    display: inline-block;
}
*, *:hover, *:focus,
a, a:hover, a:focus,
button, button:hover, button:focus,
input, input:hover, input:focus{
    text-decoration: none;
    outline: none;
}
hr{
    border-color: $color-grey;
}

::selection {
    background: #fff2a8;
}
::-moz-selection {
    background: #fff2a8;
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
    background: transparent; 
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-grey; 
    border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #999; 
}

header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    font-family: $gotham;
    ul{
        list-style: none;
        display: flex;
        margin: 0;
        li{
            margin: 0 1em;
            padding: 1em 0;
            a{
                display: block;
                color: $color-white;
                font-weight: $font-medium;
                font-size: 15px;
                &.btn{
                    margin-top: -1em;
                    border-radius: 6px;
                    padding: 0 2em;
                    line-height: 55px;
                    height: 45px;
                }
            }
            &.has-sub{
                position: relative;
                padding-right: 20px;
                &:before{
                    content:'';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: url('../images/arrow-down-red.svg') no-repeat center;
                    background-size: 100%;
                    position: absolute;
                    right: 0;
                    top: 1.2em;
                    transition: all .3s $easing-mdl;
                }
                &:after{
                    content: "";
                    width: 17px;
                    height: 15px;
                    background: url(../images/arrow-drop.svg) no-repeat bottom;
                    background-size: cover;
                    display: block;
                    position: absolute;
                    top: 2.7em;
                    right: -4px;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(-1em);
                    transition: all .3s $easing-kdk;
                }
                ul{
                    display: block;
                    position: absolute;
                    background: #F7F9FC;
                    top: 3.5em;
                    left: 0;
                    border-radius: 8px;
                    width: 380px;
                    padding: 20px 10px;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(-1em);
                    transition: all .3s $easing-kdk;
                    @media screen and (max-width:992px){
                        left:-100%;
                    }
                    li{
                        float: left;
                        margin: 0 10px;
                        padding: 0;
                        a{
                            display: block;
                            width: 100px;
                            height:  100px;
                            line-height: 100px;
                            text-align: center;
                            background: $color-white;
                            border-radius: 6px;
                            border: thin solid $color-grey-border;
                            img{
                                height: 40px;
                            }
                        }
                        p{
                            font-size: 13px;
                            margin: 15px 0 8px;
                            line-height: 15px;
                        }
                        &:hover{
                            a{
                                background: $color-blue;
                            }
                            p{
                                font-weight: $font-medium;
                            }
                        }
                        &.red-square{
                            a{
                                background: linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
                                line-height: 18px;
                                text-align: left;
                                position: relative;
                                .icon{
                                    width: 13px;
                                    height: 13px;
                                    background: url(../images/arrow-right.svg) no-repeat;
                                    background-size: cover;
                                    float: right;
                                    margin: .7em;
                                    animation: arrowRight 2.4s infinite;
                                }
                                span{
                                    display: block;
                                    max-width: 90px;
                                    position: absolute;
                                    bottom: .5em;
                                    left: 1em;
                                    color: $color-white;
                                }
                            }
                        }
                        // &:nth-child(2){
                        //     a img{
                        //         width: 35px;
                        //     }
                        // }
                        // &:nth-child(3){
                        //     a img{
                        //         width: 35px;
                        //     }
                        // }
                        // &:nth-child(7){
                        //     a img{
                        //         width: 35px;
                        //     }
                        // }
                    }
                }
                &:hover{
                    &:before{
                        background-image: url('../images/arrow-down.svg');
                        transform: rotate(180deg);
                    }
                    &:after{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(-.3em);
                    }
                    ul{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }
                ul.drop-features{
                    width: 580px;
                    padding: 0;
                    overflow: hidden;
                    .sub-top{
                        padding: 1em 2em 1em;
                        h5{
                            margin: 1em 0 .5em;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            color: $color-black-light;
                            margin: 0;
                        }
                        .special-links{
                            a{
                                display: inline-block;
                                width: 48%;
                                margin-bottom: 0;
                                padding: 1em;
                                border-radius: 6px;
                                position: relative;
                                .icon{
                                    width: 35px;
                                    height: 35px;
                                    background-repeat: no-repeat;
                                    background-size: 100%;
                                    position: absolute;
                                    left: 0em;
                                    top: -2px;
                                    &.icon-special1{
                                        background-image: url(../images/icon-feature-red/red-system.svg);
                                    }
                                    &.icon-special2{
                                        background-image: url(../images/icon-feature-red/red-service.svg);
                                    }
                                    &.icon-special3{
                                        background-image: url(../images/icon-feature-red/red-support.svg);
                                    }
                                    &.icon-special4{
                                        background-image: url(../images/icon-feature-red/red-notification.svg);
                                    }
                                    &.icon-special5{
                                        background-image: url(../images/icon-feature-red/red-secure.svg);
                                    }
                                    &.icon-special6{
                                        background-image: url(../images/icon-feature-red/red-edupay.svg);
                                    }
                                }
                                span{
                                    font-size: 15px;
                                    font-weight: $font-medium;
                                    padding-left: 3em;
                                    padding-top: 8px;
                                    color: $color-blue;
                                    display: inline-block;
                                }
                                &:hover{
                                    background: $color-white;
                                    box-shadow: 10px 10px 40px 0px #ececec;
                                }
                            }
                        }
                    }
                    .sub-bottom{
                        padding: 1em 2em;
                        background: $color-white;
                        h5{
                            margin: 1em 0 .5em;
                            font-size: 18px;
                        }
                        .modern-icons{
                            clear: both;
                            height: 115px;
                            .modern-icon{
                                padding: .8em 1em;
                                border-radius: 6px;
                                position: relative;
                                width: 50%;
                                float: left;
                                .icon{
                                    width: 35px;
                                    height: 35px;
                                    background-repeat: no-repeat;
                                    background-size: 100%;
                                    position: absolute;
                                    left: 1em;
                                    top: 10px;
                                    &.icon-modern1{
                                        background-image: url(../images/icon-feature-blue/blue-multi-level.svg);
                                    }
                                    &.icon-modern2{
                                        background-image: url(../images/icon-feature-blue/blue-multi-curriculum.svg);
                                    }
                                    &.icon-modern3{
                                        background-image: url(../images/icon-feature-blue/blue-multi-user.svg);
                                    }
                                    &.icon-modern4{
                                        background-image: url(../images/icon-feature-blue/blue-multi-platform.svg);
                                    }
                                }
                                span{
                                    font-size: 15px;
                                    font-weight: $font-medium;
                                    padding-left: 3em;
                                    padding-top: 8px;
                                    color: $color-blue;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    .sub-btns{
                        padding: 2em;
                        background: #0E4AA2;
                        >.wrap-flex{
                            justify-content: space-between;
                            padding-top: 1em;
                            .btn{
                                width: 47%;
                                text-align: center;
                            }
                        }
                        .btn-download{
                            position: relative;
                            border: thin solid rgba(252, 252, 252, 0.3);
                            overflow: hidden;
                            
                            span{
                                position: relative;
                                z-index: 1;
                                color: $color-white;
                            }
                            &:after{
                                content: '';
                                display: block;
                                background: $color-white;
                                position: absolute;
                                top:0;
                                left:0;
                                right:0;
                                bottom:0;
                                transition: all .3s $easing-mdl;
                                transform: translateX(-102%);
                            }
                            &:hover{
                                color: $color-blue;
                                border-color: $color-white;
                                span{
                                    color: $color-blue;
                                }
                                &:after{
                                    transform: translateX(0);
                                }
                            }
                        }
                        .btn-request{
                            position: relative;
                            border: thin solid transparent;
                            overflow: hidden;
                            span{
                                position: relative;
                                z-index: 1;
                            }
                            &:after{
                                content: '';
                                display: block;
                                background: linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
                                position: absolute;
                                top:0;
                                left:0;
                                right:0;
                                bottom:0;
                                transition: all .3s $easing-mdl;
                                transform: translateX(0);
                            }
                            &:hover{
                                color: $color-red;
                                border-color: $color-red;
                                &:after{
                                    transform: translateX(102%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .right-menu{
        .btn-phone{
            position: relative;
            border: thin solid rgba(252, 252, 252, 0.3);
            overflow: hidden;
            span{
                position: relative;
                z-index: 1;
            }
            .fa{
                color: rgba(255,255,255,.3);
            }
            &:after{
                content: '';
                display: block;
                background: $color-white;
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                transition: all .3s $easing-mdl;
                transform: translateX(-100%);
            }
            &:hover{
                color: $color-red;
                border-color: $color-white;
                &:after{
                    transform: translateX(0);
                }
                .fa{
                    color: $color-red; 
                }
            }
        }
        .btn-request{
            position: relative;
            border: thin solid transparent;
            overflow: hidden;
            span{
                position: relative;
                z-index: 1;
            }
            &:after{
                content: '';
                display: block;
                background: linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                transition: all .3s $easing-mdl;
                transform: translateX(0);
            }
            &:hover{
                color: $color-red;
                border-color: $color-red;
                &:after{
                    transform: translateX(102%);
                }
            }
        }
    }
}
.logo-wrap{
    padding-top: 5px;
}
.dark-logo{
    display:none;
}
input::-webkit-input-placeholder {
    color: #999;
}

form{
    .field-input{
        border-radius: 6px;
        background: $color-white;
        padding: 0 2em;
        line-height: 50px;
        border: 0;
        font-size: 16px;
        color: $color-blue;
        border: thin solid transparent;
        width: 100%;
        &:focus{
            border-color: $color-grey;
        }
    }
    .field-select{
        border-radius: 6px;
        background: $color-white;
        padding: 0 2em;
        line-height: 55px;
        height: 50px;
        color: $color-blue;
        border: thin solid transparent;
        width: 100%;
        &.open{
            border-color: $color-grey;
        }
        &:after{
            border-color: $color-red;
            margin-right: 10px;
        }
        .list{
            right: 0;
            z-index: 2;
            max-height: 250px;
            overflow-y: auto;
            .option{
                height: 40px;
                line-height: 50px;
            }
        }
    }
    .field-submit{
        color: $color-white;
        text-transform: uppercase;
        padding: 0 2em;
        border: 0;
        border-radius: 6px;
        position: relative;
        font-size: 16px;
        border: thin solid $color-red;
        overflow: hidden;
        height: 50px;
        &.with-icon{
            padding-right: 3em;
        }
        span{
            position: relative;
            z-index: 1;
            display: block;
            line-height: 60px;
        }
        &:after{
            content: '';
            display: block;
            background: linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
            position: absolute;
            top:0;
            left:0;
            right:-5px;
            bottom:0;
            transition: all .3s $easing-mdl;
            // transform: translateX(0);
        }
        .icon-arrow-right{
            position: absolute;
            right: -25px;
            top: 10px;
            &:before{
                content: '';
                width: 15px;
                height: 15px;
                display: block;
                background: url(../images/icon-arrow-right.svg) no-repeat;
                margin: .5em auto;
            }
        }
        &:hover{
            color: $color-red;
            border-color: $color-red;
            &:after{
                transform: translateX(120%);
            }
            .icon-arrow-right{
                &:before{
                    background-image: url(../images/icon-arrow-right-red.svg);
                }
            }
        }
    }
}

dialog {
    position: absolute;
    left: 0; right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    border: solid;
    padding: 0;
    background: white;
    color: black;
    display: block;
    top: 0;
    bottom: 0;
    border-radius: 7px;
    min-width: 597px;
    &:not([open]) {
        display: block !important;
    }
}
.mdl-dialog-wrap{
    background: rgba(0,0,0,.2);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s $easing-mdl;
    z-index: 3;
    &.open{
        opacity: 1;
        visibility: visible;
        transition: all .3s $easing-mdl;
    }
    .mdl-dialog{
        border:0;
        overflow: hidden;
        .mdl-dialog__title{
            text-align: center;
            padding: 20px 0;
            font-weight: $font-medium;
            line-height: 16px;
            border-bottom: thin solid $color-grey;
            font-size: 16px;
        }
        .mdl-dialog__content{
            padding: 2em 2.5em;
            min-height: 350px;
            h5{
                margin: 0 0 10px;
                font-size: 15px;
                color: $color-blue;
            }
            .field-group{
                label:not(.radio-label){
                    font-weight: $font-medium;
                    margin: 25px 0 5px;
                }
            }

        }
        .mdl-dialog__actions{
            padding: 2em 2.5em;
            border-top: thin solid $color-grey;
            .close{
                background: none;
                border: 0;
                cursor: pointer;
                i{
                    color: $color-grey;
                }
            }
        }
        .dialog-title{
            padding: 1em 2em;
            h3{
                font-size: 24px;
                margin: 1em 0 0;
                color: $color-blue;
                text-align: center;
            }
            .btn-x-close{
                position: absolute;
                top: 1em;
                right: 1em;
                padding: 0;
                border: 0;
                font-size: 16px;
                .icon{
                    width: 25px;
                    height: 25px;
                    display: block;
                    background: #ec1d24 url(../images/icon-plus.svg) no-repeat center 80%;
                    border-radius: 50%;
                    background-position: 50%;
                    background-size: 60%;
                    position: relative;
                    transform: rotate(45deg);
                }
            }
        }
        .dialog-content{
            padding: 0 5em 2em;
            p{
                text-align: center;
            }
            input[type="email"]{
                width: 100%;
                border: thin solid $color-grey-light;
            }
        }
        .dialog-btns{
            padding: 1em 2em;
            text-align: center;
            background: $color-grey-light;
            &.wrap-flex{
                justify-content: flex-end;
                .btn{
                    margin-left: 1em;
                }
            }
        }
    }
}

.page-wrapper{
    overflow: hidden;
}

.btn{
    padding: 0 2.5em;
    font-size: 15px;
    line-height: 60px;
    height: 50px;
    border-radius: 6px;
    white-space: nowrap;
    &.btn-white-o{
        border: thin solid $color-white;
        color: $color-white;
        position: relative;
        overflow: hidden;
        span{
            position: relative;
            z-index: 1;
            display: block;
            line-height: 58px;
            font-weight: 500;
        }
        &:after{
            content: '';
            display: block;
            background: $color-white;
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            transition: all .3s $easing-mdl;
            transform: translateX(-110%);
        }
        &:hover{
            color: $color-red;
            &:after{
                transform: translateX(0);
            }
        }
    }
    &.btn-red{
        border: thin solid transparent;
        position: relative;
        overflow: hidden;
        color: $color-white;
        span{
            position: relative;
            z-index: 1;
        }
        &:after{
            content: '';
            display: block;
            background: linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
            position: absolute;
            top:0;
            left:0;
            right:-5px;
            bottom:0;
            transition: all .3s $easing-mdl;
            transform: translateX(0);
        }
        &:hover{
            color: $color-red;
            border-color: $color-red;
            &:after{
                transform: translateX(102%);
            }
        }
    }
}

.ui-autocomplete{
    width: 400px !important;
    background: #fff;
    padding: 0 !important;
    margin: 0 !important;

    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68,68,68,.11);
    box-sizing: border-box;
    margin-top: 5px !important;
    overflow: hidden;
    z-index: 1;
    li{
        cursor: pointer;
        font-weight: 400;
        height: 40px;
        line-height: 50px;
        list-style: none;
        outline: 0;
        padding-left: 18px;
        padding-right: 29px;
        text-align: left;
        font-family: $gotham;
        &:hover{
            background-color: #f6f6f6;
        }
    }
  }
.form-pintro{
    .field-group{
        display: flex;
        position: relative;
        >*{
            flex-grow: 1;
        }
        // &.mdl-cell--12-col{
        //     input, textarea{
        //         width: calc(100% - 16px);
        //     }
        // }
    }
    input[type="text"], 
    input[type="number"], 
    input[type="email"],
    input[type="password"],
    input[type="search"],
    textarea{
        font-family: $avenir;
        background: $color-white;
        font-size: 15px;
        padding: 0 1em;
        height: 45px;
        border-radius: 6px;
        border: 0;
        // width: 100%;
    }
    textarea{
        height: auto;
        padding: 1em;
        width: 100%;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-black-light;
    }
    ::-moz-placeholder { /* Firefox 19+ */
    color: $color-black-light;
    }
    :-ms-input-placeholder { /* IE 10+ */
    color: $color-black-light;
    }
    :-moz-placeholder { /* Firefox 18- */
    color: $color-black-light;
    }
    .nice-select{
        border: 0;
        border-radius: 6px;
        height: 45px;
        line-height: 53px;
        color: $color-black-light;
        font-family: $avenir;
        font-size: 15px;
        .current{
            margin-top: -2px;
            display: block
        }
        &:after{
            border: 0;
            width: 18px;
            height: 18px;
            background: url(../images/icon-selectbox.svg) no-repeat;
            background-size: 100%;
            margin-top: -8px;
            transform: rotate(0deg);
            transform-origin: center;
        }
        &.open{
            &:after{
                transform: rotate(180deg);
            }
        }
        .list{
            width: 100%;
        }
        .option{
            line-height: 48px;
            list-style: none;
            font-size: 14px;
            height: 40px;
        }
    }
    .icon-password{
        width: 25px;
        height: 25px;
        background: url(../images/icon-password.svg) no-repeat;
        background-size: 100%;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .label-checkbox{
        display: block;
        position: relative;
        padding-left: 35px;
        input[type="checkbox"]{
            appearance: none;
            background-color: transparent;
            background-size: 65%;
            background-position: center;
            background-repeat: no-repeat;
            border: thin solid $color-white;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            position: absolute;
            top: -8px;
            left: 0;
            &:checked{
                background-color: $color-white;
                background-image: url(../images/icon-checkbox.svg);
            }
        }
        span{
            color: $color-white;
        }
    }
}

footer{
    .footer-top{
        position: relative;
        &:before{
            content: '';
            border-top: thin solid $color-grey-light;
            display: block;
            left: 0;
            right: 0;
            top: 75px;
            position: absolute;
        }
        .mdl-grid{
            justify-content: center;
            .mdl-cell{
                width: 18%;
                h4{
                    color: $color-blue;
                    font-size: 16px;
                    font-family: $gotham;
                    font-weight: $font-bold;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    li{
                        a{
                            font-size: 15px;
                            display: block;
                            padding: 8px 0;
                            color: $color-black-light;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom {
        background: $color-grey-light;
        height: auto;
        text-align: center;
        .mdl-cell--8-col{
            a{
                float: left;
            }
            span{
                display: inline-block;
                margin-left: 1.5em;
                padding: 13px 0;
                font-size: 16px;
                line-height: 26px;
                float: left;
            }
        }
        .mdl-cell--4-col{
            p{
                padding: 11px 0;
                margin: 0;
                color: $color-grey-text;
                span{
                    font-weight: $font-bold;
                    color: $color-blue;
                }
            }
        }
    }
}

.wrap-center{
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
}
.wrap-flex{
    display: flex;
    position: relative;
}
.flex-end{
    justify-content: flex-end;
}


.m-0{
    margin: 0;
}
.mb-0{
    margin-bottom: 0;
}
.mt-1{
    margin-top: 1em;
}
.mb-2{
    margin-bottom: 2em;
}
.p-0{
    padding: 0;
}
.pb-0{
    padding-bottom: 0;
}
.pt-0{
    padding-top: 0 !important;
}
.plr-0{
    padding-left: 0;
    padding-right: 0;
}
.pt-1{
    padding-top: 1em;
}
.pt-2{
    padding-top: 2em;
}
.b-0{
    border: 0 !important;
}
