.hero-request {
    height: auto;
    min-height: 750px;
    background: url(../images/bg-request.png) no-repeat top center; 
    background-size: cover;
    position: relative;
    .hero-caption{
        padding: 10em 0 8em;
        h2{
            font-size: 52px;
        }
        .hero-text{
            padding: 0 5em;
            p{
                a{
                    color: $color-white;
                    font-weight: $font-medium;
                }
            }
            ul{
                padding: 0;
                list-style: none;
                li{
                    position: relative;
                    padding-left: 60px;
                    margin: 1.5em 0;
                    .icon{
                        width: 45px;
                        height: 45px;
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                       background-size: 100%;
                       background-repeat: no-repeat;
                       border-radius: 50%;
                       box-shadow: -3px 6px 10px 0px rgba(234,4,29,0.35);
                       &.icon-tour{
                           background-image: url(../images/icon-tour.svg);
                       } 
                       &.icon-price{
                           background-image: url(../images/icon-price.svg);
                           top: -1em;
                       } 
                       &.icon-qa{
                           background-image: url(../images/icon-qa.svg);
                           top: -.7em;
                       } 
                    }
                }
            }
        }
    }
}