.card{
    max-width:1250px;
    background:#fff;
    border-radius:20px;
    margin:0 auto;  
    padding-top:20px;
    h2{
        font-size:32px;
        @media screen and (max-width:768px){
            font-size:18px;
            line-height: 1.5em;
        }
    }
    &.card-with-tabs{
        padding-top:0;
        overflow: hidden;
        @media screen and (max-width:768px){
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    &.card-pay{
        background:#0A387C;
        padding-top:0;
        display:flex;
        flex-flow:row wrap;
        color:#fff;
        overflow: hidden;
        .payment{   
            flex:1 0 33%;
            min-height:200px;
            padding:30px;
            transition: all .8s $easing-kdk;
            p{
                margin:10px 0;
                font-size:13px;
            }
            .payment-icon{
                padding:15px;
                height:53px;
                width: 53px;
                text-align: center;
                background:#063170;
                border-radius:100%;
                display: inline-block;
                transition: all 0.2s ease-out;
                img:last-child{
                    display: none;
                }
            }
            .payment-bank{
                display:flex;
                flex-flow:row wrap;
                align-items: center;
                > div{
                    margin:15px 30px 15px 0;
                    img{
                        &:first-child{
                            display: none;
                        }
                    }
    
                }
                img{
                    height:25px;
                    max-width: 100%;
                }
            }
            &:hover,&.active{
                background:#fff;
                color:#0A387C;
                .payment-icon{
                    background:linear-gradient(235.68deg, #6CC0F7 -2.14%, #3A8CEB 62.2%);
                    img{
                        &:first-child{
                            display: none;
                        }
                        &:last-child{
                            display: inline-block;
                        }
                    }
                }
                .payment-bank > div > img{
                    &:first-child{
                        display: inline-block;
                    }
                    &:last-child{
                        display: none;
                    }
                }  
            }
        }
         
    }
}