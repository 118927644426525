.section-price{
    padding-top: 5em;
    padding-bottom: 5em;
    background: #161f4a url(../images/background.jpg) no-repeat;
    height: auto;
    min-height: 750px;
    text-align: center;
    @media screen and (max-width:768px){
        padding-bottom:0;
    }
    h1{
        color:$color-yellow;
        font-size:52px;
        opacity: 0;
        transform: translateY(1em);
        transition: 1s all $easing-kdk;
        @media screen and (max-width:768px){
            font-size:32px;
        }
        &.inview{
            opacity: 1;
            transform: translateY(0);
        }
    }
    .card{
        opacity:0;
        transform: translateY(50px);
        transition: 1s all $easing-kdk;
        transition-delay: 2s;
        &.inview{
            opacity: 1;
            transform: translateY(0);
        }
        @media screen and (max-width:768px){
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    h2 > span{
        display:inline-block;
        transform: translateY(1em);
        transition: 1s all $easing-kdk;
        transition-delay: 2.5s;
        &:last-child{
            opacity:0;
            transition-delay: 2.7s;
        }
        &.inview{
            opacity: 1;
            transform: translateY(0);
        }
    }
    .accordion{
        opacity: 0;
        transform: translateY(50px);
        transition: 1s all $easing-kdk;
        transition-delay: 1;
        &.inview{
            opacity:1;
            transform: translateY(0);
        }
    }
}