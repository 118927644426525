.hero-auth{
    background-image: url(../images/bg-auth.png);
    h3{
        font-size: 28px;
    }
    .auth-tabs{
        button{
            border: 0;
            background: linear-gradient(to right, #1e5799 0%,#b5000c 0%,#ff0624 100%);
            color: $color-white;
            padding: 1em 2em;
            line-height: 28px;
            height: 50px;
            border-radius: 6px 6px 0 0;
            transition: all .3s $easing-kdk;
            &.active{
                background: $color-blue-bg;
            }
        }
    }
    .auth-wrapper{
        background: linear-gradient(to bottom, #1c3570 0%,#1a2c58 100%);
        position: relative;
        .auth-box{
            opacity: 0;
            visibility: hidden;
            transition: all 1s $easing-kdk;
            position: relative;
            padding: 1em;
            &.open{
                opacity: 1;
                visibility: visible;
            }
            &:last-child{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            label{
                font-size: 14px;
                a{
                    color: $color-white;
                    border-bottom: thin solid $color-white;
                    &:hover{
                        color: $color-yellow;
                        border-color: $color-yellow;
                    }
                }
            }
        }
    }
}