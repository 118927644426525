@media (min-width:769px){
    .btn-menu{
        display: none;
    }
    .mobile-hide{
        display: block;
    }
    .mobile-only{
        display: none;
    }
}
@media (max-width:768px){
    .mobile-hide{
        display: none;
    }
    .mobile-only{
        display: block;
    }
    .btn-menu{
        display: block;
        position: absolute;
        top: 1.3em;
        right: 2em;
        display: block;
        width: 30px;
        height: 30px;
        padding: 0;
        z-index: 1;
        border: 0;
        background: transparent;
        &:before{
            content: '';
            width: 100%;
            border-top: 2px solid $color-red;
            display: block;
            transform: rotate(0);
            transition: transform .3s $easing-mdl;
        }
        span{
            width: 100%;
            border-top: 2px solid $color-red;
            display: block;
            margin: 5px 0;
            transform: rotate(0);
            transition: transform .3s $easing-mdl;
        }
        &:after{
            content: '';
            width: 100%;
            border-top: 2px solid $color-red;
            display: block;
        }
        &.open{
            &:before{
                content: '';
                width: 100%;
                border-top: 2px solid $color-red;
                display: block;
                transform: rotate(45deg) translate(1px, -5px);
                transform-origin: top left;
            }
            span{
                transform: rotate(-45deg);
                transform-origin: center;
            }
            &:after{
                display: none;
            }
        }
    }
    html, body{
        min-width: auto;
        width: 100vw;
        overflow-x: hidden;
        max-width: 480px;
        margin: 0 auto;
    }
    .wrap-flex{
        display: block;
    }
    header{
        .main-header{
            padding: 0 1em;
            max-height: 75px;
            overflow: hidden;
            transition: all .3s $easing-kdk;
            background: $color-blue;
            box-shadow: 0 3px 10px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1);
            &.open{
                max-height: 100vh;
            }
            &.white-header{
                background: $color-white;
            }
        }
        .main-menu{
            transition: none;
        }
        ul{
            display: block;
            padding: 0;
            margin-top: 10px;
            li{
                margin: 0;
                padding: 1em 0 .5em;
                border-bottom: thin solid hsla(0,0%,99%,.3);
                &.has-sub{
                    &:before{
                        display: none;
                    }
                    ul{
                        display: none;
                    }
                    &:after{
                        display: none;
                    }
                }
            }
        }
        .right-menu{
            ul li{
                border: 0;
            }
        }
    }
    .is-light{
        header .main-header{
            background-color:#d3dfeb;
        }
    }
    .section-appdemo{
        padding-top: 3em;
        .right-side{
            position: absolute;
            padding: 0;
            right: 0;
            left: 0;
            padding: 0 20px;
        }
        img{
            max-width: 55%;
            padding-top: 130%;
            margin-left: 15%;
        }
        .download-links{
            display: block;
            .btn {
                width: 100%;
                text-align: center;
            }
        }
    } 
    .info-page{
        .info-title{
            padding: 7em 0 2em;
            >.wrap-center {
                padding: 0 28px;
            }
        }
        .info-content {
            padding: 2em 12px;
        }
        
    }
    .hero-request .hero-caption{
        padding: 7em 0;
        .hero-text {
            padding: 0 1em;
        }
    }
    .hero-auth h3{
        line-height: 45px;
    } 
    
    .section-hero {
        .hero-home .slick-track {
            min-height: 700px;
        }
        .hero-slide{
            min-height: 700px;
            .hero-bg{
                display: none;
            }
            .hero-caption{
                padding-bottom: 2em;
                >.wrap-center{
                    display: block;
                }
                .hero-desc{
                    width: 100%;
                    padding: 0;
                    transform: scale(.8);
                    margin-top: -12em;
                    .hero-links{
                        margin: 0;
                        @media(max-width: 375px){
                            display: none;
                        }
                    }
                }
                .hero-featured{
                    width: 100%;
                    transform: scale(.5);
                    margin-top: -30%;
                    margin-left: 3em;
                    @media(max-width: 375px){
                        margin-top: -38%;
                    }
                    .layers{
                        width: auto;
                    }
                }
            }
            &.slide0{
                .hero-caption{
                    .hero-featured{
                        margin-top: -30%;
                        margin-left: 3em;
                        .layer1{
                            width: 220px;
                        }
                        .layer2{
                            width: 280px;
                        }
                    }
                }
            }
        } 
        .section-next{
            display: none;
        }
        .slick-dots{
            position: absolute;
            margin: 0;
            padding: 0;
            width: 100%;
            text-align: center;
            justify-content: center;
            bottom: 2em;
        }
    }
    .section-merchant:before{
        height: 5em;
        top: -4em;
    }
    .section-merchant .merchant-logos ul{
        padding: 1em 1.5em;
        display: block;
        text-align: center;
        li{
            transform: scale(.8);
            display: inline-block;
        }
    }
    .section-merchant .pintro-info .pintro-services>p {
        margin: 1em auto;
    }
    .section-merchant .pintro-info .pintro-video img {
        width: calc(100% - 16px);
    }
    .section-merchant .pintro-info .pintro-counts{
        display: block;
        .pintro-count-item {
            padding: 0;
            align-items: center;
            text-align: center;
            flex-flow: column;
            margin-bottom: 1.5em;
            >div:first-child{
                padding:0;
            }
        }
    } 
    .section-merchant .pintro-info .pintro-services {
        text-align: center;
        padding: 0 1.5em;
        ul{
            display: block;
            li{
                width: 50%;
                float: left;
                &:after{
                    display: none;
                }
            }
        }
    }
    .section-environment{
        // max-height: 600px;
        overflow: hidden;
        padding: 2em 28px;
        img {
            max-width: 140%;
            margin: 1em -4em;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
        .platform-wrapper {
            // transform: scale(.35);
            // transform-origin: top left;
            // margin-left: -3em;
            transform: none;
            margin: 0;
            width: auto;
            padding-top: 15em;
            .platform-pops{
                position: relative;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
            .pop-group {
                position: relative;
                top: auto !important;
                bottom: auto !important;
                left: auto !important;
                right: auto !important;
                margin-bottom: 1em;
                height: auto !important;
                .pop-btn{
                    display: none;
                }
                .pop-box{
                    opacity: 1;
                    visibility: visible;
                    transform: none;
                    max-width: none;
                    margin: 0 !important;
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    
    .mdl-dialog-wrap .mdl-dialog {
        border: 0;
        overflow: hidden;
        width: 100%;
        min-width: auto;
        max-width: 450px;
        .dialog-content {
            padding: 0 2em 2em;
        }
    }
    .slick-slide img {
        display: block;
        width: 100%;
        margin-left: -1em;
    }
    .section-customers {
        padding: 3em 20px 0;
        .review-customer {
            padding: 3em 0 0;
            text-align: center;
            .customer-slider{
                .slick-dots {
                    margin: 0;
                    padding: 2em 0;
                }
                .customer-photo img{
                    width: 100%;
                    margin: 0;
                }
            } 
        }
    }
    .team-slider{
        margin-top: -2em;
        .team-photo {
            transform: translate(0em, 3em) scale(.8);
        }
    } 
    .section-team .slide-team .desc {
        margin-top: -2em;
    }
    .social-media {
        margin-top: 0em;
    }
    .section-pricing {
        padding: 50px 20px;
    }
    
    .section-team {
        padding: 150px 12px 50px;
    }
    .section-gallery-team{
        margin: 50px 20px;
    }
    .section-contact{
        padding: 50px 12px;
    }
    .teamgallery .mdl-cell {
        padding: 15px 0;
        margin: 0;
    }
    .pricing-table {
        padding: 0;
    }
    .hero-about .mdl-grid {
        padding: 14em 20px 0;
    }

    .hero-feature{
        padding: 0 12px;
    }
    .feature-page{
        .hero-feature .hero-content .hero-img img{
            position: relative;
            top: 0;
            max-width: 150%;
            margin-left: -25%;
        }
        .card{
            border-radius: 0;
            background: $color-blue;
        }
        .card-tabs{
            display: none;
        }
        .card-tabs-content{
            background: transparent;
            padding: 0 2em;
            .tabs-contents{
                display: block;
                background: #f2f0f0;
                margin-bottom: 2em;
                border-radius: 20px;
                position: relative;
                padding-bottom: 17em;
                .content-img{
                    position: absolute;
                    bottom: 2em;
                    left: 1em;
                    right: 1em;
                }
                .content-details{
                    padding: 0;
                }
            }
        } 
    } 
    .accordion .accordion-content ul li{
        &.title{
            margin-left: 20px;
        }
        &.filler{
            display: none;
        }
    }
    .accordion .accordion-toggle label {
        font-size: 20px;
        white-space: nowrap;
    }
    .pricing-title{
        padding: 20px;
    }

    .tabs-details .img-details {
        display: none;
    }

    footer .footer-top{
        &:before{
            display: none;
        }
        .mdl-grid{
            display: block;
            .mdl-cell{
                width: 100%;
                padding: 0 12px;
                h4{
                    margin: 0;
                }
                ul{
                    margin: 0;
                    margin-bottom: 15px;
                }
            }
        }
    } 
    .footer-bottom{
        .mdl-cell--8-col{
            margin: 0;
            margin-bottom: -10px;
            width: 100%;
            padding: 0 15px 0 20px;
        }
        .mdl-cell--4-col{
            margin: 0;
        }
    }
    .team-slider .slick-dots {
        margin: 0;
        padding: 0;
        list-style: none;
        justify-content: flex-start;
        z-index: 1;
        position: relative;
        width: 50%;
        padding-right: 3em;
        transform: none;
        padding-left: 8px;
    }
}

@media (min-height: 800px){
    .slick-dots{
        top: 60%;
    }
}